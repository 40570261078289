const defaultState = {
  searchInput: "",
  partRequestList: [],
  stockItems: [],
  showPartSearchResultsModal: false,
  showAddPartRequestModal: false,
  showAddStockItemRequestModal: false,
  showAddUnserviceablePartRequestModal: false,
  showAddUnserviceableStockItemModal: false,
  showAddPartRequestModal: false,
  unserviceablePartInput: {
    Quantity: 0,
    Position: "",
    SerialNbr: "",
    StateShelfLife: "",
    Description: "",
    Date: "",
    Station: "",
    Owner: "",
    TechLogRef: "",
    Operator: "",
    ReasonforRemoval: "",
  },
  partQuantityInput: {
    Qty: 0,
    UsrPriorityLevel: "",
    UsrPriorityDescription: "",
    UsrNeedBy: "",
    note: "",
  },
  chosenStockItem: null,
  unserviceableStockItemInput: {
    InventoryID: "",
    Description: "",
    note: "",
  },
};

const state = {
  part: defaultState,
};

export { state as default, defaultState };
