import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setPartSearchInputAction: action((state, searchInput) => {
    state.part.searchInput = searchInput;
  }),
  setPartRequestListAction: action((state, list) => {
    state.part.partRequestList = list;
  }),
  setStockItemsAction: action((state, list) => {
    state.part.stockItems = list;
  }),
  showPartSearchResultsModalAction: action((state, show) => {
    state.part.showPartSearchResultsModal = show;
  }),
  showAddPartRequestModalAction: action((state, show) => {
    state.part.showAddPartRequestModal = show;
  }),
  showAddStockItemRequestModalAction: action((state, show) => {
    state.part.showAddStockItemRequestModal = show;
    state.part.unserviceableStockItemInput.InventoryID = state.part.searchInput;
  }),
  showAddUnserviceablePartRequestModalAction: action((state, show) => {
    state.part.showAddUnserviceablePartRequestModal = show;
  }),
  showAddUnserviceableStockItemModalAction: action((state, show) => {
    state.part.showAddUnserviceableStockItemModal = show;
  }),
  setUnserviceablePartInputAction: action((state, input) => {
    state.part.unserviceablePartInput = {
      ...state.part.unserviceablePartInput,
      ...input,
    };
  }),
  resetUnserviceablePartInputAction: action((state) => {
    state.part.unserviceablePartInput = defaultState.unserviceablePartInput;
  }),
  setPartQuantityInputAction: action((state, input) => {
    state.part.partQuantityInput = {
      ...state.part.partQuantityInput,
      ...input,
    };
  }),
  resetPartQuantityInputAction: action((state) => {
    state.part.partQuantityInput = defaultState.partQuantityInput;
  }),
  resetPartStateAction: action((state) => {
    state.part = defaultState;
  }),
  setChosenStockItemAction: action((state, stockItem) => {
    state.part.chosenStockItem = stockItem;
  }),
  setUnserviceableStockItemInputAction: action((state, input) => {
    state.part.unserviceableStockItemInput = {
      ...state.part.unserviceableStockItemInput,
      ...input,
    };
  }),
  resetUnserviceableStockItemInputAction: action((state, input) => {
    state.part.unserviceableStockItemInput =
      defaultState.unserviceableStockItemInput;
  }),
};

export default actions;
